import React from 'react'
import { Link } from "react-router-dom";
import OurFeaturedProjectsSlider from '../../component/slidercomponent/OurFeaturedProjectsSlider'

const OurFeaturedProjects = () => {
  return (
    <>
      <div className="ltn__product-slider-area ltn__product-gutter pb-90 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Highly Recommended</h6>
                <h1 className="section-title">Our Featured Projects</h1>
              </div>
            </div>
          </div>
          <OurFeaturedProjectsSlider/>
          </div>
        </div>
  

    </>
  )
}

export default OurFeaturedProjects