import React from 'react'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>

      <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" data-bs-bg="img/1.jpg--">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                <div className="coll-to-info text-color-white">
                  <h1>Looking for a dream home?</h1>
                  <p>We can help you realize your dream of a new home</p>
                </div>
                <div className="btn-wrapper">
                  <Link className="btn btn-effect-3 btn-white" to="/Contact">Explore Properties <i className="icon-next" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      {/* <img src="assets/img/logo-2.png" alt="Logo" /> */}
                    </div>
                  </div>
                  {/* <p>Lorem Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum is
                    dummy text of the printing.</p> */}
                  <h4 className="footer-title">Address</h4>

                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <p>16-2-705/10/A/B, Super Bazar, Chowrasta Malakpet, Hyderabad</p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p><a to="tel:+0123-456789">+91 8897731126</a></p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p><a href="mailto:sritirumalaestateshyd@gmail.com">sritirumalaestateshyd@gmail.com</a></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="ltn__social-media mt-20">
                    <ul>
                      <li><a to="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                      <li><a to="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
                      <li><a to="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                      <li><a to="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Services</h4>
                  <div className="footer-menu">
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/About">About</Link></li>
                      <li><Link to="/Services">Services</Link></li>
                      <li><Link to="/Gallery">Gallery</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Projects</h4>
                  <div className="footer-menu">
                    <ul>
                      <li><Link to="/NewProject">New Project</Link></li>
                      <li><Link to="/RunningProjects">Running Projects</Link></li>
                      <li><Link to="/UpComingProject">Up Coming Project</Link></li>
                      <li><Link to="/CompletedProjects">Completed Projects</Link></li>
                      <li><Link to="/Contact">Contact us</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="footer-widget footer-newsletter-widget">
                  <h4 className="footer-title">Newsletter</h4>
                  <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
                  <div className="footer-newsletter">
                    <form>
                      <input type="email" name="" placeholder="Email*" />
                      <div className="btn-wrapper">
                        <button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow" /></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
          <div className="container-fluid ltn__border-top-2">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="ltn__copyright-design clearfix">
                  <p>© 2024  Sri Tirumala Estates | <a href='https://dexterous.in/' target="_blank">Design by Dexterous Technology </a> <span className="" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>


    </>
  )
}

export default Footer