import React from 'react'

const About = () => {
    return (
        <>
            <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title"></h1>
                                <div className="ltn__breadcrumb-list">
                                    <ul>
                                        <li><a to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</a></li>
                                        <li>Service</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__team-details-area mb-120 bg1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="ltn__team-details-member-info text-center mb-40">
                                <div className="team-details-img mt-5">
                                    <img src="/assets/img/team/event1.png" alt="Team Member Image" />
                                </div>
                                {/* <h2>Rosalina D.</h2> */}
                                <h6 className="text-uppercase ltn__secondary-color">Director</h6>
                                <div className="ltn__social-media-3">
                                    <ul>
                                        <li><a to="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                        <li><a to="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
                                        <li><a to="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 mt-5">
                            <div className="ltn__team-details-member-info-details">
                                <h2>Our MD's Message</h2>
                                <p>Our plots are for lower, upper and for all common Middle class people.
                                    These plots are "the best" for all the investors who expect good return's in
                                    short period.
                                </p>
                                <p>Sri Tirumala Estates does all residential layouts and construction
                                    projects. All these projects have cemented a concrete relationship with our
                                    customers with our professional approach to every project from selection of
                                    site, planning, execution to delivery. Sri Tirumala Estates understands
                                    owning a house is a big dream to the middle class, so we make all our efforts
                                    to exceed customers expectations in terms of quality, service and safety.</p>
                                <p>We invite you to join this wonderful journey that we are embarking upon.
                                    It's vision to create a home as exclusive and as enchanting as you. All our
                                    layouts are built with passion, capturing every aspiration and creating an
                                    address that stands out uniquely for living, enjoying and investing. Come,
                                    let's write a new chapter in lifestyle living!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__page-details-area ltn__blog-details-area mb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__blog-details-wrap">
                                <div className="ltn__page-details-inner ltn__blog-details-inner">
                                    <blockquote>
                                        <h6 className="ltn__secondary-color mt-0">About Us</h6>
                                        About Sri Tirumala Estates.
                                    </blockquote>
                                    <p>33 years old reputed, corporate company having thousand & lacks of satisfied customers.
                                        Completed many ventures in and around city. Few highlighted projects of Sri Tirumala Estates are :
                                        Project near Gurramguda, Turkayamjal Project, Pedda Amberpet Project. We have huge satisfied customer
                                        data base as we do all approved, Title clear layouts with full developments. </p>
                                    <img className="alignleft" src="/assets/img/blog/blog-details/1.jpg" alt="Image" />
                                    <p>Here at Sri Tirumala Estates, we understand the importance of time, thus we give at the
                                        most attention to the customer. It is a well know fact that trends and tastes change with the
                                        passage of time. As time passes by each decade moves on to another, the concept of fine living
                                        changes and so does the standard of living. Along with it, there are gigantic advancements in the
                                        fields of infrastructure, architecture, landscaping, technology, and education which when clubbed
                                        together with an ever evolving creative human mind has now made it possible to incorporate all these
                                        into a City of Wonders, brought together for the people to experience a lifestyle with a wide horizon,
                                        easy accessibility & availability of basic amenities.</p>
                                    <p>Sri Tirumala Estates integrates established, professional high-tech techniques
                                        specializing in the marketing & sales of residential gated communities and investment
                                        opportunities. </p>
                                    <h4>Sri Tirumala Estates</h4>
                                    <p>Sri Tirumala Estates maintains a staff of well-trained Real Estate professionals who
                                        persistently endeavor to provide excellent service for their individual clients and customers.</p>
                                    <p>Today, the world is riddled with the issue of crime and a general lack of security which obstructs
                                        and affects an individual’s freedom of living in more ways than one. Sri Tirumala Estates caters to
                                        the needs and demands of this age and time, thus, will provide an elaborate 24×7 security system in
                                        order to create a safe and secure environment for the residents to live freely in. This certainly will
                                        add to the glory of the city over time.
                                    </p>
                                    <p>Sustainable development is yet another issue that is talked about too much but is rarely implemented.
                                        In contrast, Sri Tirumala Estates has detailed plans on our projects for the appreciation of customer’s
                                        investment. we do a lot of survey regarding the up-coming developments and future Government and Private
                                        Projects coming up around our ventures. So we confidently grauntee our customers that they will have a
                                        good price growth on there investments. That's the basic and Main Secret of our Sri Tirumala Estates
                                        Growth graph.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div className="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---">
                <div className="container">
                    <div className="row">
                        <div class="col-lg-12">
                            <div class="section-title-area ltn__section-title-2--- text-center">
                                <h6 class="section-subtitle section-subtitle-2 ltn__secondary-color">Mission & Vision</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row ltn__custom-gutter--- justify-content-center">
                        <div className="col-lg-6 col-sm-6 col-12">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                                <div className="ltn__feature-icon">
                                    <img src="/assets/img/icons/icon-img/21.png" alt="#" />
                                </div>
                                <div className="ltn__feature-info">
                                    <h3><a to="service-details.html">Mission</a></h3>
                                    <p>At Sri Tirumala Estates, we understand that investing in the property is one of the biggest decisions you'll ever make. Naturally, you want the best possible outcome. you also want sound advice you can trust and discreet professional service you can count on. Sri Tirumala Estates is not just another real Estates agency. we pride ourselves on being different in many positive ways that give our clients a significant edge when investing in the property.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                                <div className="ltn__feature-icon">
                                    <img src="assets/img/icons/icon-img/22.png" alt="#" />
                                </div>
                                <div className="ltn__feature-info">
                                    <h3><a to="service-details.html">Vision</a></h3>
                                    <p>Our Md Sir's vision is to have a piece of land or a home to Everybody,
                                        No matter what they are in their finincal background. From the begining, we
                                        sell plots on installments to make a "common man's dream come true" in terms
                                        of there dream home. Even now we are able to sell HMDA layout plots on easy
                                        installments which we can proudly say this that we are able to do so, only
                                        because of the great support from our old esteemed Customer's, who treat us as
                                        their family.</p>

                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                                <div className="ltn__feature-icon">
                                    <img src="assets/img/icons/icon-img/23.png" alt="#" />
                                </div>
                                <div className="ltn__feature-info">
                                    <h3><a to="service-details.html">Sell a home</a></h3>
                                    <p>over 1 million+ homes for sale available on the website, we can match you with a
                                        house you will want to call home.</p>
                                    
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default About