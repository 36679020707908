import React from 'react'
import { Link } from "react-router-dom";


const Gallery = () => {
    return (
        <>

            <div className="ltn__breadcrumb-area text-left bg-overlay-white-30  bg-image " style={{ background: "url(./assets/img/bg/23.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title"></h1>
                                <div className="ltn__breadcrumb-list mt-lg-5">
                                    <ul>
                                        <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                                        <li>Gallery</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__gallery-area mb-120">
                <div className="container">
                    <div className="section-title-area ltn__section-title-2--- text-center">
                        <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Running Project Gallery   </h6>
                        <h1 className="section-title">Sri Balaji Nagar</h1>
                    </div>
                    <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
                        <div className="ltn__gallery-sizer col-1" />
                        <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="/assets/img/gallery/1.webp" >
                                        <img src="/assets/img/gallery/up1.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>

                        <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="#ltn__inline_description_1" >
                                        <img src="/assets/img/gallery/up2.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                                        <img src="/assets/img/gallery/up3.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="https://player.vimeo.com/video/47362400" >
                                        <img src="/assets/img/gallery/up4.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="media/1.mp4" >
                                        <img src="/assets/img/gallery/up5.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                                        <img src="/assets/img/gallery/up6.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="/assets/img/gallery/1.webp"  data-bs-lc-caption="Your caption Here">
                                        <img src="/assets/img/gallery/up7.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar </a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <a to="/assets/img/gallery/no-image.webp" >
                                        <img src="/assets/img/gallery/up8.webp" alt="Image" />

                                    </a>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to=""></a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>
                        <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                            <div className="ltn__gallery-item-inner">
                                <div className="ltn__gallery-item-img">
                                    <Link to="/assets/img/gallery/no-image.webp" >
                                        <img src="/assets/img/gallery/up9.webp" alt="Image" />

                                    </Link>
                                </div>
                                <div className="ltn__gallery-item-info">
                                    <h4><a to="">Sri Balaji Nagar</a></h4>
                                    <p>Running Project Gallery   </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section>
                <div className="ltn__gallery-area mb-120">
                    <div className="container">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Running Project Gallery   </h6>
                            <h1 className="section-title">Sri Balaji Nagar</h1>
                        </div>
                        <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
                            <div className="ltn__gallery-sizer col-1" />
                            <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp" >
                                            <img src="/assets/img/gallery/running1.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>

                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="#ltn__inline_description_1" >
                                            <img src="/assets/img/gallery/running2.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                                            <img src="/assets/img/gallery/running3.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://player.vimeo.com/video/47362400" >
                                            <img src="/assets/img/gallery/running4.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="media/1.mp4" >
                                            <img src="/assets/img/gallery/running5.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                                            <img src="/assets/img/gallery/running6.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp"  data-bs-lc-caption="Your caption Here">
                                            <img src="/assets/img/gallery/running7.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar </a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/running8.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to=""></a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <Link to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/running9.webp" alt="Image" />

                                        </Link>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Nagar</a></h4>
                                        <p>Running Project Gallery   </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="ltn__gallery-area mb-120">
                    <div className="container">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Completed Project Gallery</h6>
                            <h1 className="section-title">Sri Balaji Homes</h1>
                        </div>
                        <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
                            <div className="ltn__gallery-sizer col-1" />
                            <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp" >
                                            <img src="/assets/img/gallery/comp1.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes </a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>

                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="#ltn__inline_description_1" >
                                            <img src="/assets/img/gallery/comp2.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes </a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                                            <img src="/assets/img/gallery/comp3.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes </a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://player.vimeo.com/video/47362400" >
                                            <img src="/assets/img/gallery/comp4.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes</a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="media/1.mp4" >
                                            <img src="/assets/img/gallery/comp5.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes </a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                                            <img src="/assets/img/gallery/comp6.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes </a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp"  data-bs-lc-caption="Your caption Here">
                                            <img src="/assets/img/gallery/comp7.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes </a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/comp8.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes</a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <Link to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/comp9.webp" alt="Image" />

                                        </Link>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sri Balaji Homes</a></h4>
                                        <p>Completed Project Gallery   </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery