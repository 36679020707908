import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function OurFeaturedProjectsSlider() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >
                <div className="row ltn__product-slider-item-three-active slick-arrow-1">

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/SreeAmbikaNagar"><img src="assets/img/product-3/sree ananda sai naga.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">New Project</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/SreeAmbikaNagar"><i className="flaticon-pin" /> Sree Ambika Nagar</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/SreeAmbikaNagar">Sree Ambika Nagar</Link>
                                    </h2>
                                    <div className="product-description">
                                        {/* <p>Sree Ambika Nagar is located Near RAIGIRI, Mothkur Road, Yadadri Dist., Telangana - 508116.</p> */}
                                        <p>Sy. No. 612/A/2, 612/A/1, 612/13, 612/A, 612/E, 612/A/1, 612/12,
                                            Pathangi Village, Choutupal Mandal, Yadadri Bhuvangiri District.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/SreeAnandaSaiNagar"><img src="assets/img/product-3/sree ananda sai nagar.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">RUNNING PROJECT</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/SreeAnandaSaiNagar"><i className="flaticon-pin" /> Sree Ananda Sai Nagar</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/SreeAnandaSaiNagar">Sree Ananda Sai Nagar</Link>
                                    </h2>
                                    <div className="product-description">
                                        <p>Sree Ananda Sai Nagar Is Located @ Thirumalapuram  Gram Panchyath Chinthapally Mandal, Nalgonda District.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/SriLakshmiNagar"><img src="assets/img/product-3/Sri Lakshmi Nagar.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Running project</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/SriLakshmiNagar"><i className="flaticon-pin" /> Sri Lakshmi Nagar</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/SriLakshmiNagar">Sri Lakshmi Nagar</Link>
                                    </h2>
                                    <div className="product-description">
                                        <p>Sri Lakshmi Narasimha Nagar is located Near RAIGIRI, Mothkur Road, Yadadri Dist., Telangana - 508116.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/NewProject"><img src="assets/img/product-3/Sri Lakshmi Narasimha Nagar.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Running project</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/NewProject"><i className="flaticon-pin" /> Sri Lakshmi Narasimha Nagar</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/NewProject">Sri Lakshmi Narasimha Nagar</Link>
                                    </h2>
                                    <div className="product-description">
                                        <p>Sri Lakshmi Narasimha Nagar is located Near RAIGIRI, Mothkur Road, Yadadri Dist., Telangana - 508116.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/RunningProjects"><img src="assets/img/product-3/Sri Balaji Nagar.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Running Project</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/RunningProjects"><i className="flaticon-pin" /> Sri Balaji Nagar</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/RunningProjects">Sri Balaji Nagar</Link>
                                    </h2>
                                    <div className="product-description">
                                        <p>Sri Balaji Nagar is Located Near Ibrahimpatnam, Opp. Tirumala Dairy Products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/UpComingProject"><img src="assets/img/product-3/sree ananda sai nagar.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Running Project</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/UpComingProject"><i className="flaticon-pin" /> Sri Tirumala Nagar (Gungal)</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/UpComingProject">Sri Tirumala Nagar (Gungal)</Link>
                                    </h2>
                                    <div className="product-description">
                                        <p>It Is Located Next to Gurunanak Engineering College,  Agapally Village, Yacharam Mandal, Hyderabad.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <Link to="/CompletedProjects"><img src="assets/img/product-3/Balaji Homes.webp" alt="#" /></Link>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Completed Project</li>
                                        </ul>
                                    </div>
                                    <div className="product-img-location-gallery">
                                        <div className="product-img-location">
                                            <ul>
                                                <li>
                                                    <Link to="/CompletedProjects"><i className="flaticon-pin" /> Balaji Homes</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h2 className="product-title"><Link to="/CompletedProjects">Balaji Homes</Link>
                                    </h2>
                                    <div className="product-description">
                                        <p>Balaji Homes Is Located Near Gurramguda, Nadergul, Hyderabad East Inaugurated By : Sri T. Devender Goud, On 10-01-2015.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>








                </div>
            </Swiper>
        </>
    );
}
