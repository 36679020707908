import React from 'react'
import { Link } from "react-router-dom";


const CompletedProjects = () => {
  return (
    <>
      <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title"></h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                    <li>Completed Projects</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-category">
                        <a href="">Completed Projects</a>
                      </li>
                    </ul>
                  </div>
                  <h1>Sri Balaji Homes</h1>
                  <h4 className="title-2">Description</h4>
                  <p>Sri Balaji Homes is a well designed gated Community Project with a world class Infrastructure for Common Middle and Upper Middle class People we make all ouR efforts to exceed customers Expectation in terms of Quality, Safety and Service.</p>
                  <div className="col-lg-12">
                    <div className="product-details-apartments-info-list  section-bg-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Dimensions</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>Plotted Area</label> <span>14,599 SQ.YDS.</span></li>
                              <li><label>Open Area</label> <span>0,855 SQ.YDS.</span></li>
                              <li><label>Roads Area</label> <span>6,205 SQ.YDS.</span></li>
                              <li><label>Total Area</label> <span>21,659 SQ.YDS.</span></li>
                              <li><label>OR</label> <span>AC 4-19 qts.</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="ltn__about-us-area pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Salient Features</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Layout with 100% Vaasthu and clear Marketable Title
                              </li>
                              <li>
                                <i className="icon-done" />
                                30 Ft. Black Top Road
                              </li>
                              <li>
                                <i className="icon-done" />
                                Well Designed underground drainage system

                              </li>
                              <li>
                                <i className="icon-done" />
                                Adequate water supply with overhead tank


                              </li>
                              <li>
                                <i className="icon-done" />
                                Avenue plantation and foot paths

                              </li>
                              <li>
                                <i className="icon-done" />
                                Well laid out electric poles and street lighting

                              </li>
                              <li>
                                <i className="icon-done" />
                                Layout approved by Grampanchayath,
                              </li>
                              <li>
                                <i className="icon-done" />
                                Loans will be provided for purchasing the plots & House Constructions as per eligibility

                              </li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ltn__about-us-area section-bg-1 pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Project Heiglights</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Panchayath water connection, Krishna & Bore Water as a backup
                              </li>
                              <li>
                                <i className="icon-done" />
                                Gated community with round the clock security

                              </li>
                              <li>
                                <i className="icon-done" />
                                Avenue Plantation on both side of road
                              </li>
                              <li>
                                <i className="icon-done" />
                                Covered drainage for sewerage
                              </li>
                              <li>
                                <i className="icon-done" />
                                Common Septic Tank
                              </li>
                              <li>
                                <i className="icon-done" />
                                Black TOp roads with street lights
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ltn__about-us-area pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Special Features</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Beside Sagar Highway adjaccent to B.N. Reddy nagar colony and Vanasthalipuram.
                              </li>
                              <li>
                                <i className="icon-done" />
                                24 hrs transport facility from all city centers
                              </li>
                              <li>
                                <i className="icon-done" />
                                Schools, Colleges, Pharmacy & Engg., Zee School (CBSE), TCS are located

                              </li>
                              <li>
                                <i className="icon-done" />
                                peaceful & Non-polluted area
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="title-2">Sri Balaji Homes (Layout Plan)</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/compLay.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/compLay_page-0001.webp" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="title-2">Sri Balaji Homes (Location Plan)</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/comlocation.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/sodapdf-converted_Page_1.jpeg" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="ltn__gallery-area mb-120">
          <div className="container">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Completed Project </h6>
              <h1 className="section-title">Location Images</h1>
            </div>
            <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
              <div className="ltn__gallery-sizer col-1" />
              <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/1.webp" >
                      <img src="/assets/img/gallery/comp1.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes </a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>

              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="#ltn__inline_description_1" >
                      <img src="/assets/img/gallery/comp2.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes </a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                      <img src="/assets/img/gallery/comp3.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes </a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://player.vimeo.com/video/47362400" >
                      <img src="/assets/img/gallery/comp4.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes</a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="media/1.mp4" >
                      <img src="/assets/img/gallery/comp5.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes </a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                      <img src="/assets/img/gallery/comp6.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes </a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/1.webp"  data-bs-lc-caption="Your caption Here">
                      <img src="/assets/img/gallery/comp7.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes </a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/no-image.webp" >
                      <img src="/assets/img/gallery/comp8.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes</a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <Link to="/assets/img/gallery/no-image.webp" >
                      <img src="/assets/img/gallery/comp9.webp" alt="Image" />

                    </Link>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Homes</a></h4>
                    <p>Completed Project Gallery   </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CompletedProjects