import React from 'react'
import { Link } from "react-router-dom";


const SreeAnandaSaiNagar = () => {
  return (
    <>
      <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title"></h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                    <li>Sree Ananda Sai Nagar</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-category">
                        <a href="">Running project</a>
                      </li>
                    </ul>
                  </div>
                  <h1>Sree Ananda Sai Nagar</h1>
                  <h4 className="title-2">Description</h4>
                  <p>Our vision is to give a dream home to all classes of peoples exclusives they are SRI TIRUMALA ESTATES maintains a well trained professionals staff to provide excellent service to our customers, we cater all the amenities to the need and demand of the customer & provide 24 X 7security in order to create an safe and secure environment for the NRI and every common investor who expect high appreciation in a shorter period and we make all our effort to exceed customers’ expectations in term of quality & service.</p>
                  <p>We invite you to join in our wonderful and peaceful paradise created especially for 'U' in this temple city with great passion.</p>
                  <div className="col-lg-12">
                    <div className="product-details-apartments-info-list  section-bg-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Dimensions</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>Plotted Area</label> <span>98,818.28 SQ.YDS.

                              </span></li>
                              <li><label>Park Area</label> <span>16906.12 SQ.YDS.</span></li>
                              <li><label>Road Area</label> <span>53336.80 SQ.YDS.</span></li>
                              <li><label>Total Area</label> <span>169061.20 SQ.YDS</span></li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Schemes (Rs per Sq Yd)</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>General Plots</label>
                                <span>RS. 6,500/-</span></li>
                              <li><label>40" Feet Road</label> <span>250 (extra)</span></li>
                              <li><label>Any East Facing</label> <span>200 (extra)</span></li>
                              <li><label>Any North Facing</label> <span>200 (extra)</span></li>
                              <li><label> Any North East</label> <span>750 (extra)</span></li>
                              <li><label>Corner Plots</label> <span>500 (extra)</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/saibrouchur123.pdf" target="_blank">Download</a>
                  </li>
                  <br />

                  <div className="ltn__about-us-area pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Plot & Plantation Highlights</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                DTCP APPROVED LAYOUT
                              </li>
                              <li>
                                <i className="icon-done" />
                                33 FT & 40 FT BLOCK TOP ROAD
                              </li>
                              <li>
                                <i className="icon-done" />
                                ELECTRICITY POLES & TRANSFORMER
                              </li>
                              <li>
                                <i className="icon-done" />
                                WATER PIPELINE CONNECTION
                              </li>
                              <li>
                                <i className="icon-done" />
                                OVERHEAD WATER TANK

                              </li>
                              <li>
                                <i className="icon-done" />
                                UNDERGROUND DRAINAGE
                              </li>
                              <li>
                                <i className="icon-done" />
                                CHILDRENS PLAY AREA & PARKS
                              </li>
                              <li>
                                <i className="icon-done" />
                                SANDALWOOD PLANTATION MAINTAINED BY AGRICULTURE EXPERT
                              </li>
                              <li>
                                <i className="icon-done" />
                                SWIMMING POOL & GUEST HOUSE
                              </li>
                              <li>
                                <i className="icon-done" />
                                CLUB HOUSE & GYM

                              </li>
                              <li>
                                <i className="icon-done" />
                                COTTAGES & SIE OFFICE
                              </li>
                              <li>
                                <i className="icon-done" />
                                AS PER VASTU, CLEAR TITLE & SPOT REGISTRATION
                              </li>
                              <li>
                                <i className="icon-done" />
                                24/7 SECURITY, WATCH DOGS, CCTV.
                              </li>
                              <li>
                                <i className="icon-done" />
                                AVENUE PLANTATION
                              </li>
                              <li>
                                <i className="icon-done" />
                                TOTAL LAND FENCING
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ltn__about-us-area section-bg-1 pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Benefits</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                ONE PLOT - TRIPLE BENEFIT: THE WINNER'S STRATEGY SREE ANANDA SAI NAGAR OFFERS PLOTS WITH DTCP APPROVED LAYOUT IN ECO FRIENDLY ATMOSPHERE WITH SUSTAINABLE APPRECIATION.
                              </li>
                              <li>
                                <i className="icon-done" />
                                BENEFIT 1 : YOU WILL GET PROMISING APPRECIATION FOR THE PLOT COST
                              </li>
                              <li>
                                <i className="icon-done" />
                                BENEFIT 2 : WE OFFER YOU 1 SRI GANDHAM PLANT FOR EVERY 10 SQYDS, WHICH WILL BE MAINTAINED BY THE COMPANY. THIS APPRECIATION WHAT YOU GET WITH THE YIELD IN 12-15 YEARS WILL BE BEYOND YOUR IMAGINATION.
                              </li>
                              <li>
                                <i className="icon-done" />
                                BENEFIT 3 : YOU CAN ENJOY YOUR TIME IN A PEACEFUL. POLLUTION FREE. BEAUTIFUL & ORGANIC ENVIRONMENT BY KEEPING YOUR STRESS ASIDE YOU CAN REST. RELAX & REJOICE.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ltn__about-us-area  pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Location Heiglights</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Very Near To CHINTHAPALLY SAI BABA TEMPLE
                              </li>
                              <li>
                                <i className="icon-done" />
                                03 Minutes Drive To AMARAVATHI EXPRESS HIGHWAY
                              </li>
                              <li>
                                <i className="icon-done" />
                                08 Minutes Drive To CHALIDONDA LAKSHMI NARASIMHA SWAMY TEMPLE
                              </li>
                              <li>
                                <i className="icon-done" />
                                10 Minutes Drive From PROPOSED REGIONAL RING ROAD
                              </li>
                              <li>
                                <i className="icon-done" />
                                20 Minutes Drive From PHAMA CITY & AMAZON DATA CENTER
                              </li>
                              <li>
                                <i className="icon-done" />
                                25 Minutes Drive From TATA, AEROSPACE SEZ @ ELIMINEDU
                              </li>
                              <li>
                                <i className="icon-done" />
                                30 Minutes Drive From BDL NSG. OCTOPUS
                              </li>
                              <li>
                                <i className="icon-done" />
                                45 Minutes Drive From Outer Ring Road
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ltn__about-us-area section-bg-1 pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Venture Features</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Offers Plots With DTCP Approved Layout
                              </li>
                              <li>
                                <i className="icon-done" />
                                Eco Friendly Atmosphere With Sustainable Appreciation
                              </li>
                              <li>
                                <i className="icon-done" />
                                You Will Get Promising Appreciation For The Plots Cost.
                              </li>
                              <li>
                                <i className="icon-done" />
                                We Offer You 1 SRI GANDHAM PLANT For Every 10 SQYDS,
                              </li>
                              <li>
                                <i className="icon-done" />
                                Beautiful & Organic Environment
                              </li>
                              <li>
                                <i className="icon-done" />
                                Your Stress Aside You Can Rest, Relax & Rejoice.
                              </li>
                              <li>
                                <i className="icon-done" />
                                24 X 7 Security
                              </li>
                              <li>
                                <i className="icon-done" />
                                The Yield In 12-15 Years Will Be Beyond Your Imagination
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <h4 className="title-2">Sree Ananda Sai Nagar (Layout Plan).</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/srimap123.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/srimap123_page-0001.jpg" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container'>
        <h4 className="title-2">Find On Map.</h4>

        <div style={{maxWidth: '100%', overflow: 'hidden', color: 'red', width: 1200, height: 300}}><div id="canvas-for-googlemap" style={{height: '100%', width: '100%', maxWidth: '100%'}}><iframe style={{height: '100%', width: '100%', border: 0}} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Sree+Ananda+Sai+Nagar&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="code-for-google-map" href="https://www.bootstrapskins.com/themes" id="authorize-maps-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{__html: "#canvas-for-googlemap img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>

        {/* <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: 1200, height: 300 }}><div id="google-maps-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/directions?origin=Sree+Ananda+Sai+Nagar&destination=Sree+Ananda+Sai+Nagar&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="embed-map-html" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="inject-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#google-maps-display .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;" }} /></div> */}
      </section>

      <section>
        <div className="ltn__gallery-area mb-120">
          <div className="container">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-lg-5">Property   </h6>
              <h1 className="section-title">Location Images</h1>
            </div>
            <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
              <div className="ltn__gallery-sizer col-1" />
              <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/1.webp" >
                      <img src="/assets/img/gallery/114A3273.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>

              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="#ltn__inline_description_1" >
                      <img src="/assets/img/gallery/114A3274.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                      <img src="/assets/img/gallery/114A3275.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://player.vimeo.com/video/47362400" >
                      <img src="/assets/img/gallery/114A3280.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="media/1.mp4" >
                      <img src="/assets/img/gallery/114A3291.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                      <img src="/assets/img/gallery/114A3302.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


      
    </>
  )
}

export default SreeAnandaSaiNagar