import React from 'react'
import { Link } from "react-router-dom";


const HomeBanner = () => {
    return (
        <>

            <div className="ltn__slider-area ltn__slider-3  section-bg-2">
                <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
                    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal---  bg-overlay-theme-black-60" style={{ background: "url(./assets/img/slider/banner.jpg)" }}>
                        <div className="ltn__slide-item-inner text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 align-self-center">
                                        <div className="slide-item-info">
                                            <div className="slide-item-info-inner ltn__slide-animation">
                                                <div className="slide-video mb-50 d-none">
                                                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" to="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
                                                        <i className="fa fa-play" />
                                                    </a>
                                                </div>
                                                <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Welcome To Sri Tirumala Estates</h6>
                                                <h1 className="slide-title animated ">Find Your Dream <br /> House By Us</h1>
                                                <div className="slide-brief animated">
                                                    <p>The World Greeting Magnanimous Spaces And Verdant Greenery !</p>
                                                </div>
                                                <div className="btn-wrapper animated">
                                                    <Link to="/Contact" className="theme-btn-1 btn btn-effect-1">Make An
                                                        Enquiry</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeBanner