import React from 'react'
import { Link } from "react-router-dom";


const SreeAmbikaNagar = () => {
    return (
        <>
            <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title"></h1>
                                <div className="ltn__breadcrumb-list">
                                    <ul>
                                        <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                                        <li>New Project</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="ltn__shop-details-area pb-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                                    <div className="ltn__blog-meta">
                                        <ul>
                                            <li className="ltn__blog-category">
                                                <a href="">New Project</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h1>Sree Ambika Nagar</h1>
                                    <h4 className="title-2">Description</h4>
                                    <p>Our vision is to give you an exclusive investment
                                        Property for all classes of people which is highly
                                        Safe and secure with all amenities, easy
                                        Accessibility is also a major part which we look
                                        Into. SREE AMBIKA NAGAR is not only
                                        Highway a facing venture and also a venture on
                                        Fast growing road coming up with a lot of
                                        Government &amp; private projects.</p>
                                    <div className="col-lg-12">
                                        <div className="product-details-apartments-info-list  section-bg-1">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="ltn__blog-meta">
                                                        <ul>
                                                            <li className="ltn__blog-category">
                                                                <a href="">Dimensions</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                        <ul>
                                                            <li><label>Plotted Area</label> <span>26, 340  SQ.YDS.
                                                            </span></li>
                                                            <li><label>Open Space Area</label> <span>3,409. SQ.YDS.</span></li>
                                                            <li><label>Road Area</label> <span>13,428.43 SQ.YDS.</span></li>
                                                            <li><label>Social Infrastructure Area</label> <span>1,136.26   SQ.YDS</span></li>
                                                            <li><label>Utility Area</label> <span>449.84   SQ.YDS.</span></li>
                                                            <li><label>Total Area</label> <span> 44, 763.94  SQ.YDS</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="ltn__blog-meta">
                                                        <ul>
                                                            <li className="ltn__blog-category">
                                                                <a href="">Schemes (Rs per Sq Yd)</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                        <ul>
                                                            <li><label>General Plots</label>
                                                                <span>19,000 /- </span></li>
                                                            <li><label>SPECIAL LAUNCHING OFFER</label> <span>5000/- PER SQ YD</span></li>
                                                            <li><label>40" Feet Road Facing Plots</label> <span>250 (extra)</span></li>
                                                            <li><label>Any East Facing Plots</label> <span>200 (extra)</span></li>
                                                            <li><label> Any North Facing Plots</label> <span>200 (extra)</span></li>
                                                            <li><label> Any North East Corner Facing Plots</label> <span>750 (extra)</span></li>
                                                            <li><label>Corner Plots</label> <span>500/- (extra)</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <li className="special-link" style={{ listStyle: "none" }}>
                                        <a href="assets/img/others/sree_ambika_bro_r1.pdf" target="_blank">Download</a>
                                    </li>
                                    <br />

                                    <div className="ltn__about-us-area pb-90">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 align-self-center">
                                                    <div className="about-us-info-wrap">
                                                        <div className="section-title-area ltn__section-title-2--- mb-20">
                                                            <h1 className="section-title">Location Heiglights</h1>
                                                        </div>
                                                        <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                                                            <li>
                                                                <i className="icon-done" />
                                                                Highway Facing Venture
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Sanctioned 8 Line Road
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                5 mnts Drive From Proposed Dry Port after Pantangi
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                5 mnts Drive from Proposed Regional Ring Road
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                15 mnts Drive From Green Industrial Park with 30000 Employment

                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                15 mnts Drive from Bhoodhan Pochampally Awarded As No 1 Textile Park and Worlds Best Tourism Village
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                20 mnts Drive From Logistic Park
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                20 mnts Drive From Fruit Market @ Batasingaram
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                20 mnts Drive From Outer Ring Road
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                20 mnts Drive From World Famous Ramoji Flim City

                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Very Near to the Upcoming Govt HMDA Layout In 365 Acres @ Malakpur                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ltn__about-us-area section-bg-1 pb-90">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 align-self-center">
                                                    <div className="about-us-info-wrap">
                                                        <div className="section-title-area ltn__section-title-2--- mb-20">
                                                            <h1 className="section-title">Project Highlights</h1>
                                                        </div>
                                                        <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                                                            <li>
                                                                <i className="icon-done" />
                                                                Approved HMDA Layout
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                60' 40' & 30' ft All Black Top Roads
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Underground Drainage
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Septic Tank
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Over Head Water Tank

                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Water Pipeline Connection Till Plot
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Electrical Lines and Transformer
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Avenue Plantation
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                24x 7 Securities
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Parks with Landscaping
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                100% Vaastu
                                                            </li>
                                                            <li>
                                                                <i className="icon-done" />
                                                                Title Clear
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <h4 className="title-2">Sree Ambika Nagar (Layout Plan).</h4>
                                    <li className="special-link" style={{ listStyle: "none" }}>
                                        <a href="assets/img/others/Sree Ambika Nagar.pdf" target="_blank">Download</a>
                                    </li><br />
                                    <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                                        <div className="tab-content">
                                            <div className="tab-pane fade active show" id="liton_tab_3_2">
                                                <div className="ltn__product-tab-content-inner">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="apartments-plan-img">
                                                                <img src="assets/img/others/Sree Ambika Nagar.jpg" alt="#" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container'>
                <h4 className="title-2">Find On Map.</h4>
                <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: 1200, height: 300 }}><div id="google-maps-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=NH+65,+Lingoji+Guda,+Telangana+508252&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="embed-map-html" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="inject-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#google-maps-display .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;" }} /></div>
            </section>

            <section>
                <div className="ltn__gallery-area mb-120">
                    <div className="container">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-lg-5">New Project Gallery</h6>
                            <h1 className="section-title">Sree Ambika Nagar</h1>
                        </div>
                        <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
                            <div className="ltn__gallery-sizer col-1" />
                            <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp" >
                                            <img src="/assets/img/gallery/9.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>

                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="#ltn__inline_description_1" >
                                            <img src="/assets/img/gallery/10.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                                            <img src="/assets/img/gallery/2.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://player.vimeo.com/video/47362400" >
                                            <img src="/assets/img/gallery/3.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar</a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="media/1.mp4" >
                                            <img src="/assets/img/gallery/4.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                                            <img src="/assets/img/gallery/5.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp"  data-bs-lc-caption="Your caption Here">
                                            <img src="/assets/img/gallery/6.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/7.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar</a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <Link to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/8.webp" alt="Image" />

                                        </Link>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar</a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="#ltn__inline_description_1" >
                                            <img src="/assets/img/gallery/11.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                                            <img src="/assets/img/gallery/12.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://player.vimeo.com/video/47362400" >
                                            <img src="/assets/img/gallery/13.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar</a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="media/1.mp4" >
                                            <img src="/assets/img/gallery/14.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                                            <img src="/assets/img/gallery/15.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/1.webp"  data-bs-lc-caption="Your caption Here">
                                            <img src="/assets/img/gallery/16.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar </a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <a to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/17.webp" alt="Image" />

                                        </a>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar</a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                                <div className="ltn__gallery-item-inner">
                                    <div className="ltn__gallery-item-img">
                                        <Link to="/assets/img/gallery/no-image.webp" >
                                            <img src="/assets/img/gallery/1.webp" alt="Image" />

                                        </Link>
                                    </div>
                                    <div className="ltn__gallery-item-info">
                                        <h4><a to="">Sree Ambika Nagar</a></h4>
                                        <p>New Project Gallery   </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SreeAmbikaNagar