import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAboout from './home/HomeAboout'
import OurFeaturedProjects from './home/OurFeaturedProjects'
import Video from './home/Video'
import Features from './home/Features'
import DevelopmentAround from './home/DevelopmentAround'
import Testimonial from './home/Testimonial'

const Home = () => {
  return (
    <>

        <HomeBanner />
        <HomeAboout />
        <OurFeaturedProjects />
        {/* <Video /> */}
        <Features />
        <DevelopmentAround />
        <Testimonial />
    </>
  )
}

export default Home