import React from 'react'
import { Link } from "react-router-dom";


const Contact = () => {
    return (
        <>

            <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title"></h1>

                                <div className="ltn__breadcrumb-list">
                                    <ul>
                                        <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                                        <li>Contact</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__contact-address-area mb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <img src="./assets/img/icons/10.png" alt="Icon Image" />
                                </div>
                                <h3>Email Address</h3>
                                {/* <p>sritirumalaestateshyd@gmail.com </p> */}
                                <p><a href="mailto:sritirumalaestateshyd@gmail.com">sritirumalaestateshyd@gmail.com </a></p>

                                {/* <p> balajiestateshyd@gmail.com </p> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <img src="./assets/img/icons/11.png" alt="Icon Image" />
                                </div>
                                <h3>Phone Number</h3>
                                <p>+91 8897731126 <br /> +91 9347988333</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <img src="./assets/img/icons/12.png" alt="Icon Image" />
                                </div>
                                <h3>Office Address</h3>
                                <p>16-2-705/10/A/B,
                                    Super Bazar, Chowrasta,
                                    Malakpet, Hyderabad.
                                    500036</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="google-map container">
                {/* <div style={{ maxWidth: '100%', listStyle: 'none', transition: 'none', overflow: 'hidden', width: 1200, height: 600 }}><div id="google-maps-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=+Super+Bazar,+Chowrasta,+Malakpet,+Hyder+abad.500036&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><Link className="google-map-html" href="https://www.bootstrapskins.com/themes" id="get-data-for-embed-map">premium bootstrap themes</Link><style dangerouslySetInnerHTML={{ __html: "#google-maps-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div> */}

                <div style={{ textDecoration: 'none', overflow: 'hidden', maxWidth: '100%', width: 1200, height: 400 }}><div id="google-maps-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Second+Hand+Shop,+Professors+Colony,+Andhra+Colony,+Malakpet,+Hyderabad,+Telangana+500036&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="the-googlemap-enabler" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="grab-map-info">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#google-maps-display .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;" }} /></div>


            </div>



        </>
    )
}

export default Contact