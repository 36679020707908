import React from 'react'

const Features = () => {
    return (
        <>

            <div className="ltn__search-by-place-area before-bg-top bg-image-top---  pb-70" data-bs-bg="img/bg/20.webp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center---">
                                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color mt-lg-5">Sri Tirumala Estates</h6>
                                <h1 className="section-title">FEATURES <br />
                                    </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row ltn__search-by-place-slider-1-active slick-arrow-1">
                        <div className="col-lg-4">
                            <div className="ltn__search-by-place-item">
                                <div className="search-by-place-img">
                                <a to="product-details.html"><img src="./assets/img/product-3/Electricity.webp" alt="#" /></a>
                                    <div className="search-by-place-badge">
                                        <ul>
                                            <li>Sri Tirumala Estates</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="search-by-place-info">
                                    <h4><a to="product-details.html">Electricity</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ltn__search-by-place-item">
                                <div className="search-by-place-img">
                                    <a to="product-details.html"><img src="./assets/img/product-3/30 ft 60 ft Road.webp" alt="#" /></a>
                                    <div className="search-by-place-badge">
                                        <ul>
                                            <li>Sri Tirumala Estates</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="search-by-place-info">
                                    <h4><a to="product-details.html">30 ft / 60 ft Road</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ltn__search-by-place-item">
                                <div className="search-by-place-img">
                                    <a to="product-details.html"><img src="./assets/img/product-3/24x7 Security.webp" alt="#" /></a>
                                    <div className="search-by-place-badge">
                                        <ul>
                                            <li>Sri Tirumala Estates</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="search-by-place-info">
                                    <h4><a to="product-details.html">24x7 Security</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Features