import React from 'react'

const Testimonial = () => {
    return (
        <>
            <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-120 pb-70 bg1" data-bs-bg="img/bg/20.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonial</h6>
                                <h1 className="section-title">Clients Feedback</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
                        <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                                <div className="ltn__testimoni-info">
                                    <p><i className="flaticon-left-quote-1" />She was always very responsive and professional. We will refer Sri Tirumala Estates to all of our friends and family in the market. She made our first home buying experience phenomenal!</p>
                                    <div className="ltn__testimoni-info-inner"><br/>
                                        <br/><br/>
                                        <div className="ltn__testimoni-img">
                                            <img src="assets/img/testimonial/profile.png" alt="#" />
                                        </div>
                                        <div className="ltn__testimoni-name-designation">
                                            <h5>Shubham Kumar</h5>
                                            {/* <label>Selling Agents</label> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                                <div className="ltn__testimoni-info">
                                    <p><i className="flaticon-left-quote-1" />I am pleased and blessed with my experience with Arielle Rios. She was the most professional and kindest REALTOR. Arielle went above and beyond and I am so thankful for everything she has done for me.</p>
                                    <div className="ltn__testimoni-info-inner">
                                        <div className="ltn__testimoni-img">
                                            <img src="/assets/img/testimonial/profile.png" alt="#" />
                                        </div><br/>
                                        <br/><br/>
                                        <div className="ltn__testimoni-name-designation">
                                            <h5>Suresh Reddy</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                                <div className="ltn__testimoni-info">
                                    <p><i className="flaticon-left-quote-1" />
                                    Thank you so much for all you do! You've turned a stressful situation into something more manageable, being responsive and on top of everything, and just overall knowledgeable and truly helping me find a home I'd love.</p>
                                    <div className="ltn__testimoni-info-inner">
                                        <div className="ltn__testimoni-img">
                                            <img src="/assets/img/testimonial/profile.png" alt="#" />
                                        </div>
                                        <div className="ltn__testimoni-name-designation">
                                            <h5>Siva Kumar</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Testimonial