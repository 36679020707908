import React from 'react'
import { Link } from "react-router-dom";


const HomeAboout = () => {
    return (
        <>

            <div className="ltn__about-us-area pt-120 pb-90 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                                <img src="/assets/img/others/7.png" alt="About Us Image" />
                                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                                    <div className="ltn__video-img ltn__animation-pulse1">
                                        <img src="/assets/img/others/8.jpg" alt="video popup bg image" />
                                        <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/watch?v=dqxXPsjh9GI" target="_blank">
                                            <i className="fa fa-play" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="about-us-info-wrap">
                                <div className="section-title-area ltn__section-title-2--- mb-20">
                                    <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
                                    <h1 className="section-title">WELCOME TO A FRESH NEW WORLD OF SRI TIRUMALA ESTATES<span>.</span></h1>
                                    <p>The world greeting magnanimous spaces and verdant greenery ! Accentuating floating water bodies and heavenly fragrances ! 
                                    The world enthralling laidback and grand villas articulated with the finest of international luxury and traditional Vastu. 
                                    A sheer experience of delight created to mark a fresh imprint in your memories and begin life anew.</p>
                                </div>
                                <ul className="ltn__list-item-half clearfix">
                                    <li>
                                        <i className="flaticon-home-2" />
                                        Residential Property Development
                                    </li>
                                    <li>
                                        <i className="flaticon-mountain" />
                                        Commercial Property
                                    </li>
                                    <li>
                                        <i className="flaticon-heart" />
                                        Township Development
                                    </li>
                                    <li>
                                        <i className="flaticon-secure" />
                                        Complete 24/7 Security
                                    </li>
                                </ul>
                                <div className="btn-wrapper animated">
                                    <Link to="/About" className="theme-btn-1 btn btn-effect-1">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeAboout