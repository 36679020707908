import React from 'react'
import { Link } from "react-router-dom";


const SriLakshmiNagar = () => {
  return (
    <>
      <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title"></h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                    <li>Sri Lakshmi Nagar</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-category">
                        <a href="">Running project</a>
                      </li>
                    </ul>
                  </div>
                  <h1>Sri Lakshmi Nagar</h1>
                  <h4 className="title-2">Description</h4>
                  <p>Our vision is to give a dream home to all classes of peoples exclusives they are SRI TIRUMALA ESTATES maintains a well trained professionals staff to provide excellent service to our customers, we cater all the amenities to the need and demand of the customer & provide 24 X 7security in order to create an safe and secure environment for the NRI and every common investor who expect high appreciation in a shorter period and we make all our effort to exceed customers’ expectations in term of quality & service.</p>
                  <p>We invite you to join in our wonderful and peaceful paradise created especially for 'U' in this temple city with great passion.</p>
                  <div className="col-lg-12">
                    <div className="product-details-apartments-info-list  section-bg-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Dimensions</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>Plotted Area</label> <span>74,273 SQ.YDS.

                              </span></li>
                              <li><label>Open Space Area</label> <span>9,669 SQ.YDS.</span></li>
                              <li><label>Social str. Area</label> <span>3,316 SQ.YDS.</span></li>
                              <li><label>Utility Area</label> <span>1,220 SQ.YDS</span></li>
                              <li><label>Road Area</label> <span>38,572 SQ.YDS</span></li>
                              <li><label>Total Area</label> <span>1,27,050 SQ.YDS</span></li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Schemes (Rs per Sq Yd)</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>Main Road Facing</label>
                                <span>18,000</span></li>
                              <li><label>General Plots</label> <span>11,000</span></li>
                              <li><label>60" Feet Road</label> <span>500 (extra)</span></li>
                              <li><label>Corner Plots</label> <span>750 (extra)</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/SriLasheminagarBrochure.pdf" target="_blank">Download</a>
                  </li>
                  <br />

                  <div className="ltn__about-us-area pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Location Heiglights.</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                NIMS, AIIMS &Health City At Bibinagar
                              </li>
                              <li>
                                <i className="icon-done" />
                                AGI GLASS PAC (An SBU of HSIL LIMITED) Container Glass Industry At Bhongiri
                              </li>
                              <li>
                                <i className="icon-done" />
                                Vedic School, Goshala & Sculpture Institute
                              </li>
                              <li>
                                <i className="icon-done" />
                                MMTS Station At Raigiri
                              </li>
                              <li>
                                <i className="icon-done" />
                                Mini Tank Bund At Bhasavapur

                              </li>
                              <li>
                                <i className="icon-done" />
                                500Crs Reliance & 500Crs Tata - Multi Level Parking
                              </li>
                              <li>
                                <i className="icon-done" />
                                Proposed Rope Way - Tulasivanam To Yadadri
                              </li>
                              <li>
                                <i className="icon-done" />
                                400 Acres deer Park
                              </li>
                              <li>
                                <i className="icon-done" />
                                200 Acers Brundhavan Garden at Bhasavapur
                              </li>
                              <li>
                                <i className="icon-done" />
                                Acres Reservoir at Bhasavapur
                              </li>
                              <li>
                                <i className="icon-done" />
                                400 Mega Watts Thermal Plant for Yadadri
                              </li>
                              <li>
                                <i className="icon-done" />
                                World Famous jain Mandir At Kolanpaka
                              </li>
                              <li>
                                <i className="icon-done" />
                                Swetharka Ganapathi Temple at Khazipet
                              </li>
                              <li>
                                <i className="icon-done" />
                                Proposed Domestic Airport
                              </li>
                              <li>
                                <i className="icon-done" />
                                10 Minutes’ drive to 3000Crs Project of Siddha Kshetra Dhaman in 250 Acers
                              </li>
                              <li>
                                <i className="icon-done" />
                                450 Acers Infosys, Raheja IT Park, Singapore city At Ghatkesar
                              </li>
                              <li>
                                <i className="icon-done" />
                                10 Minutes Drive from Bhongiri Railway Station
                              </li>
                              <li>
                                <i className="icon-done" />
                                Many Engineering Institute & Commercials Establishments
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ltn__about-us-area section-bg-1 pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Venture Features</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                YDTA Layout
                              </li>
                              <li>
                                <i className="icon-done" />
                                60' 40' & 30' ft All Black Top Roads
                              </li>
                              <li>
                                <i className="icon-done" />
                                Electricals Lines & Street Lights
                              </li>
                              <li>
                                <i className="icon-done" />
                                Under Ground Drainage
                              </li>
                              <li>
                                <i className="icon-done" />
                                Avenue Plantation
                              </li>
                              <li>
                                <i className="icon-done" />
                                Children Play Area & Park Landscaping
                              </li>
                              <li>
                                <i className="icon-done" />
                                Over Head Tank & Till Plot Water Pipeline Connection
                              </li>
                              <li>
                                <i className="icon-done" />
                                24 X 7 Security
                              </li>
                              <li>
                                <i className="icon-done" />
                                As Per 100% Vaastu
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <h4 className="title-2">Sri Lakshmi Nagar (Layout Plan).</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/RAIGIRI-SITE2.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/RAIGIRI-SITE2_page-0001.jpg" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ltn__gallery-area mb-120">
          <div className="container">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Property   </h6>
              <h1 className="section-title">Location Images</h1>
            </div>
            <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
              <div className="ltn__gallery-sizer col-1" />
              <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/1.webp" >
                      <img src="/assets/img/gallery/SriLakshmiNarasimhaNagar1.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>

              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="#ltn__inline_description_1" >
                      <img src="/assets/img/gallery/SriLakshmiNarasimhaNagar2.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                      <img src="/assets/img/gallery/SriLakshmiNarasimhaNagar3.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://player.vimeo.com/video/47362400" >
                      <img src="/assets/img/gallery/SriLakshmiNarasimhaNagar4.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="media/1.mp4" >
                      <img src="/assets/img/gallery/SriLakshmiNarasimhaNagar5.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                      <img src="/assets/img/gallery/SriLakshmiNarasimhaNagar6.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">

                    <p>Running project   </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


      <div className='container'>
        <div className="section-title-area ltn__section-title-2--- text-center">
          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Location</h6>
        </div>
        <div style={{ overflow: 'hidden', maxWidth: '100%', width: 1200, height: 300 }}><div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Rayagiri+Rural,+Telangana+508116&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="google-map-code-enabler" href="https://www.bootstrapskins.com/themes" id="grab-map-authorization">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#embed-map-canvas img{max-height:none;max-width:none!important;background:none!important;}" }} /></div>
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default SriLakshmiNagar