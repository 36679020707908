import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import OurFeaturedProjects from "./pages/home/OurFeaturedProjects";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import Contact from "./pages/contact/Contact";
import Gallery from "./pages/gallery/Gallery";
import CompletedProjects from "./pages/project/CompletedProjects";
import RunningProjects from "./pages/project/RunningProjects";
import NewProject from "./pages/project/NewProject";
import UpComingProject from "./pages/project/UpComingProject";
import Downloads from "./pages/downloads/Downloads";
import SreeAnandaSaiNagar from "./pages/ourfeaturedprojects/SreeAnandaSaiNagar";
import SriLakshmiNagar from "./pages/ourfeaturedprojects/SriLakshmiNagar";
import SreeAmbikaNagar from "./pages/ourfeaturedprojects/SreeAmbikaNagar";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  useEffect(() => {
    if (
      window.location.href === "http://sritirumalaestates.com/" ||
      window.location.href === "http://sritirumalaestates.com" ||
      window.location.href === "http://sritirumalaestates.com/" ||
      window.location.href === "sritirumalaestates.com" ||
      window.location.href === "sritirumalaestates.com/" ||
      window.location.href === "http://sritirumalaestates.com"
      // ||
      // window.location.href === "http://localhost:3000/#/" ||
      // window.location.href === "http://localhost:3000/"
    ) {
      window.location.replace("https://sritirumalaestates.com/");
    }
  }, []);
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/CompletedProjects" element={<CompletedProjects />} />
            <Route path="/RunningProjects" element={<RunningProjects />} />
            <Route path="/NewProject" element={<NewProject />} />
            <Route path="/UpComingProject" element={<UpComingProject />} />
            <Route path="/Downloads" element={<Downloads />} />
            <Route path="/SriLakshmiNagar" element={<SriLakshmiNagar />} />
            <Route path="/SreeAnandaSaiNagar" element={<SreeAnandaSaiNagar />} />
            <Route path="/SreeAmbikaNagar" element={<SreeAmbikaNagar />} />
            <Route path="/Contact" element={<Contact />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
