import React from 'react'
import { Link } from "react-router-dom";


const RunningProjects = () => {
  return (
    <>
      <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title"></h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                    <li>Running Projects</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-category">
                        <a href="">Running Projects</a>
                      </li>
                    </ul>
                  </div>
                  <h1>Sri Balaji Nagar</h1>
                  <h4 className="title-2">Description</h4>
                  <p>Sri Balaji Nagar is a HMDA Approved Residential Layout with full developments like a gated Community which stands out uniquely for living, Enjoying & Investing.</p>
                  <div className="col-lg-12">
                    <div className="product-details-apartments-info-list  section-bg-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Dimensions</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>Plotted Area</label> <span>67530.99 SQ.YDS
                              </span></li>
                              <li><label>Developable Area (L.I.G)</label> <span>4894.79 SQ.YDS.</span></li>
                              <li><label>Developable Area (E.W.S)</label> <span>4761.85 SQ.YDS.</span></li>
                              <li><label>Roads Area</label> <span>41304.14 SQ.YDS.</span></li>
                              <li><label>Utility Area</label> <span>257.12 SQ.YDS</span></li>
                              <li><label>Park Area</label> <span>9943.31 SQ.YDS</span></li>
                              <li><label>Social Area</label> <span>3302.41 SQ.YDS</span></li>
                              <li><label>Total Area</label> <span>131994.61 SQ.YDS</span></li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-category">
                                <a href="">Schemes (Rs per Sq Yd)</a>
                              </li>
                            </ul>
                          </div>
                          <div className="apartments-info-list apartments-info-list-color mt-40---">
                            <ul>
                              <li><label>Highway Facing</label>
                                <span>15,000</span></li>
                              <li><label>General Plots</label> <span>11,000</span></li>
                              <li><label>60" Feet Road</label> <span>500 (extra)</span></li>
                              <li><label>Corner Plots</label> <span>750 (extra)</span></li>
                              <li><label> Plots</label> <span></span></li>
                              <li><label>Facing Plots</label> <span></span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/runnbro.pdf" target="_blank">Download</a>
                  </li><br />

                  <div className="ltn__about-us-area pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Location Heiglights</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Beside Nagarjuna High Way
                              </li>
                              <li>
                                <i className="icon-done" />
                                500' Outer Ring Road
                              </li>
                              <li>
                                <i className="icon-done" />
                                Opp. Tirumal Dairy Products
                              </li>
                              <li>
                                <i className="icon-done" />
                                20 Min Drive From International Airport
                              </li>
                              <li>
                                <i className="icon-done" />
                                AERO SPACE SEZ At Aadibhatla.


                              </li>
                              <li>
                                <i className="icon-done" />
                                3000 Cr. TATA HELICOPTER UNTI AT AERO SPACE SEZ, Aadibhatla, IBP.

                              </li>
                              <li>
                                <i className="icon-done" />
                                Fab City, Park, Hardware Textile City Park,
                              </li>
                              <li>
                                <i className="icon-done" />
                                NGS COMMANDOS, Octopus, at IBP
                              </li>
                              <li>
                                <i className="icon-done" />
                                Krishna Drinking Water Lines Passing through our Project,
                              </li>
                              <li>
                                <i className="icon-done" />
                                20 Engineering Colleges & 40 PG College
                              </li>
                              <li>
                                <i className="icon-done" />
                                200 acrs BDL Township

                              </li>
                              <li>
                                <i className="icon-done" />
                                Beside Krishna Water Reservoir,
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ltn__about-us-area section-bg-1 pb-90">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                              <h1 className="section-title">Venture Features</h1>
                            </div>
                            <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                              <li>
                                <i className="icon-done" />
                                Approved Layout
                              </li>
                              <li>
                                <i className="icon-done" />
                                100% Vastu

                              </li>
                              <li>
                                <i className="icon-done" />
                                40 & 30 feet black top Roads
                              </li>
                              <li>
                                <i className="icon-done" />
                                Underground Drainage
                              </li>
                              <li>
                                <i className="icon-done" />
                                Over Head Water Tank

                              </li>
                              <li>
                                <i className="icon-done" />
                                Parks with Landscape
                              </li>
                              <li>
                                <i className="icon-done" />
                                Avenue Plantation
                              </li>
                              <li>
                                <i className="icon-done" />
                                Entire Venture fencing with Entrance Arch
                              </li>
                              <li>
                                <i className="icon-done" />
                                Modern Modular Street Light
                              </li>
                              <li>
                                <i className="icon-done" />
                                24x7 Security

                              </li>
                              <li>
                                <i className="icon-done" />
                                Electrical lines & Water pipes on all roads
                              </li>
                              <li>
                                <i className="icon-done" />
                                Land with clear title, spot registration
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <h4 className="title-2">Sri Balaji Nagar (Layout Plan)</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/p1.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/p1_page-0001.webp" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="title-2">Sri Balaji Nagar (Location Plan)</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/sitemaprunning.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/sitemaprunning_page-0001.jpg" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ltn__gallery-area mb-120">
          <div className="container">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Running Project   </h6>
              <h1 className="section-title">Location Images</h1>
            </div>
            <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
              <div className="ltn__gallery-sizer col-1" />
              <div className="ltn__gallery-item filter_category_4 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/1.webp" >
                      <img src="/assets/img/gallery/up1.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>

              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="#ltn__inline_description_1" >
                      <img src="/assets/img/gallery/up2.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="//www.youtube.com/embed/6v2L2UGZJAM?version=3" >
                      <img src="/assets/img/gallery/up3.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://player.vimeo.com/video/47362400" >
                      <img src="/assets/img/gallery/up4.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="media/1.mp4" >
                      <img src="/assets/img/gallery/up5.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9076122223137!2d144.96590401578402!3d-37.81779982944919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b6af832249%3A0xe39e415e49a7c44e!2sFlinders%20Street%20Railway%20Station!5e0!3m2!1sen!2sbd!4v1598113544515!5m2!1sen!2sbd" >
                      <img src="/assets/img/gallery/up6.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/1.webp" data-bs-lc-caption="Your caption Here">
                      <img src="/assets/img/gallery/up7.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar </a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a to="/assets/img/gallery/no-image.webp" >
                      <img src="/assets/img/gallery/up8.webp" alt="Image" />

                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to=""></a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>
              <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <Link to="/assets/img/gallery/no-image.webp" >
                      <img src="/assets/img/gallery/up9.webp" alt="Image" />

                    </Link>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4><a to="">Sri Balaji Nagar</a></h4>
                    <p>Running Project Gallery   </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <div className='container'>
        <div className="section-title-area ltn__section-title-2--- text-center">
          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Location</h6>
        </div>
        <div style={{ overflow: 'hidden', maxWidth: '100%', width: 1200, height: 300 }}><div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Yacharam,+Telangana+501506&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="google-map-code-enabler" href="https://www.bootstrapskins.com/themes" id="grab-map-authorization">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#embed-map-canvas img{max-height:none;max-width:none!important;background:none!important;}" }} /></div>
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default RunningProjects