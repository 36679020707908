import React, { useState } from 'react'
import { Link } from "react-router-dom";


const Hadear = () => {
  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };
  return (
    <>

      <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---">
        <div className="ltn__header-top-area top-area-color-white">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu" >
                  <ul>
                    <li><a href="mailto:sritirumalaestateshyd@gmail.com"><i className="icon-mail" />sritirumalaestateshyd@gmail.com </a></li>
                    <li ><a href="https://maps.app.goo.gl/M6ahPqgdNjoWe8Gm7" target="_blank"><i className="icon-placeholder"   />2nd Floor, Super Bazar Chowrasta Malakpet, HYD</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      {/* <li>
                        <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                          <ul>
                            <li><Link to="#" className="dropdown-toggle"><span className="active-currency">English</span></Link>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                      <li>
                        <div className="ltn__social-media">
                          <ul>
                            <li><Link to="#" title="Facebook"><i className="fab fa-facebook-f" /></Link></li>
                            <li><Link to="#" title="Twitter"><i className="fab fa-twitter" /></Link>
                            </li>
                            <li><Link to="#" title="Instagram"><i className="fab fa-instagram" /></Link></li>
                            <li><Link to="#" title="Dribbble"><i className="fab fa-dribbble" /></Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo">
                    <Link to="/"><img src="assets/img/SRI_TIRUMALA_logo.png" alt="Logo" /></Link>
                  </div>
                  <div className="get-support clearfix d-none">
                    <div className="get-support-icon">
                      <i className="icon-call" />
                    </div>
                    <div className="get-support-info">
                      <h6>Get Support</h6>
                      <h4><Link to="tel:+123456789">123-456-789-10</Link></h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column menu-color-white">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>

                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/About">About</Link></li>

                        <li className="menu-icon"><Link to="">Projects</Link>
                          <ul>
                            <li><Link to="/NewProject">New-Project</Link></li>
                            <li><Link to="/RunningProjects">Running Projects</Link></li>
                            <li><Link to="/UpComingProject">Up Coming Project </Link></li>
                            <li><Link to="/CompletedProjects">Completed Project </Link></li>
                          </ul>
                        </li>
                        <li><Link to="/Services">Services</Link>
                        </li>
                        <li><Link to="/Gallery">Gallery</Link>
                        </li>
                        <li><Link to="/Downloads">Downloads</Link>
                        </li>

                        <li><Link to="/Contact">Contact</Link></li>
                        {/* <li className="special-link">
                          <Link to="/Contact">Make An Enquiry</Link>
                        </li> */}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>

              {toggle === false ? (
                <>
                  <div className="col--- ltn__header-options ltn__header-options-2 " onClick={() => setToggle(!toggle)}>
                    <div className="mobile-menu-toggle d-xl-none">
                      <Link to="" className="ltn__utilize-toggle">
                        <svg viewBox="0 0 800 600">
                          <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
                          <path d="M300,320 L540,320" id="middle" />
                          <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="ltn__utilize-menu-head">
                    <div className="site-logo">
                      {/* <a href="index.html"><img src="img/logo.png" alt="Logo" /></a> */}
                    </div>
                    <button className="ltn__utilize-close" onClick={() => setToggle(!toggle)}>×</button>
                  </div>

                  <div id="ltn__utilize-mobile-menu" className="" style={{ background: "white" }}>
                    <div className="ltn__utilize-menu-inner ltn__scrollbar">
                      <div className="ltn__utilize-menu">
                        <ul>
                          <li className='' onClick={() => setToggle(!toggle)}><Link to="/">Home</Link>
                          </li>
                          <li className='' onClick={() => setToggle(!toggle)}><Link to="/About">About</Link>
                          </li>

                          <li className="menu-icon" onClick={() => setToggle(!toggle)}><Link to="">Projects</Link>
                          <ul>
                            <li className='' onClick={() => setToggle(!toggle)}><Link to="/NewProject">New-Project</Link></li>
                            <li className='' onClick={() => setToggle(!toggle)}><Link to="/RunningProjects">Running Projects</Link></li>
                            <li className='' onClick={() => setToggle(!toggle)}><Link to="/UpComingProject">Up Coming Project </Link></li>
                            <li className='' onClick={() => setToggle(!toggle)}><Link to="/CompletedProjects">Completed Project </Link></li>
                          </ul>
                        </li>
                          <li className='' onClick={() => setToggle(!toggle)}><Link to="/Services">Services</Link>
                          </li>

                          <li className='' onClick={() => setToggle(!toggle)}><Link to="/Gallery">Gallery</Link></li>
                          <li className='' onClick={() => setToggle(!toggle)}><Link to="/Downloads">Downloads</Link></li>
                          <li className='' onClick={() => setToggle(!toggle)}><Link to="/Contact">Contact</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>



                </>
              )}



            </div>
          </div>
        </div>
      </header>


    </>
  )
}

export default Hadear