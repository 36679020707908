import React from 'react'
import OurFeaturedProjects from '../home/OurFeaturedProjects'

const Services = () => {
    return (
        <>

            <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title"></h1>
                                <div className="ltn__breadcrumb-list">
                                    <ul>
                                        <li><a to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</a></li>
                                        <li>Service</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OurFeaturedProjects />

        </>
    )
}

export default Services