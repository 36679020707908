import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

export default function DevelopmentAroundSlider() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >
                <div className="row ltn__product-slider-item-three-active slick-arrow-1">
                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <a to="product-details.html"><img src="assets/img/product-3/Around_1.webp" alt="#" /></a>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Our Neighbour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-description">
                                        <p>Around 20 Engineering colleges is neighbour to our upcoming venture.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <a to="product-details.html"><img src="assets/img/product-3/Around_2.webp" alt="#" /></a>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Our Neighbour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-description">
                                        <p>Around 40+ MBA | MCA colleges are situated near to our ventures.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <a to="product-details.html"><img src="assets/img/product-3/Around_3.webp" alt="#" /></a>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Our Neighbour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-description">
                                        <p>After our site 2000 acres "Filmcity" Proposal at Rachakonda.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <a to="product-details.html"><img src="assets/img/product-3/Around_4.webp" alt="#" /></a>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Our Neighbour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-description">
                                        <p>Near Ibrahimpatnam BDL, BEL, NGS, Octops, Plastic Industries.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <a to="product-details.html"><img src="assets/img/product-3/Around_6.webp" alt="#" /></a>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Our Neighbour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-description">
                                        <p>Near Adibatla - TCS, Cognizent, Tata Advanced System, are situated.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                <div className="product-img">
                                    <a to="product-details.html"><img src="assets/img/product-3/Around_5.webp" alt="#" /></a>
                                    <div className="product-badge">
                                        <ul>
                                            <li className="sale-badge bg-green">Our Neighbour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-info">
                                    <div className="product-description">
                                        <p>Near Eliminedu - 100 acres Total, Euro Space Sez is situated.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                </div>
            </Swiper>
        </>
    );
}
