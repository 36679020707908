import React from 'react'
import { Link } from "react-router-dom";


const Downloads = () => {
  return (
    <>
      <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " style={{ background: "url(/assets/img/bg/23.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title"></h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                    <li>Completed Projects</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <h4 className="title-2">Application Form</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/applicationform SRI TIRUMALA ESTATES.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/applicationform.jpg" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="title-2">Registration Form</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/reg1_page-0001.pdf" target="_blank">Download</a>
                  </li><br />
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="liton_tab_3_2">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="apartments-plan-img">
                                <img src="assets/img/others/reg1_page-0001.jpg" alt="#" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <h4 className="title-2">Sri Balaji Nagar Brochure</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/runnbro1.pdf" target="_blank">Download</a>
                  </li><br />
                  <h4 className="title-2">Sri Tirumala Nagar Brochure</h4>
                  <li className="special-link" style={{ listStyle: "none" }}>
                    <a href="assets/img/others/upbro.pdf" target="_blank">Download</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Downloads